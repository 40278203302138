import tw, { theme } from 'twin.macro'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@partials/Layout/Layout'

import Seo from '@components/Seo'
import HeaderSlider from '@components/HeaderSlider'
import PageCover from '@components/PageCover'
import Section from '@components/Section'
import Title from '@components/Title'
import Partners from '@components/Partners'

import { findComponent } from '@components/FlexPage'

import { WpPage, WpPostConnection } from '@_types/graphql-types'
import MiniPost from '@components/MiniPost'
import Button from '@components/Button'

type Home = {
  wpPage: WpPage
  featuredPosts: WpPostConnection
}

const IndexPage = ({ data: { wpPage, featuredPosts } }: PageProps<Home>) => {
  const { seo, acfHome, acfPageFields, featuredImage } = wpPage

  const { content } = acfPageFields

  return (
    <Layout
      headerCover={
        <div>
          <HeaderSlider
            slides={acfHome.carousel.map(
              ({ image, title, caption, link }, index) => {
                return (
                  <PageCover
                    key={index}
                    type="slide"
                    title={title}
                    description={caption}
                    link={link.url}
                    image={
                      <GatsbyImage
                        image={image.localFile.childImageSharp['full']}
                        alt={image.altText}
                        loading="eager"
                        objectFit="cover"
                        tw="absolute z-0 left-0 top-0 w-full h-full pointer-events-none"
                        style={{
                          position: 'absolute'
                        }}
                      />
                    }
                  />
                )
              }
            )}
          />
        </div>
      }
    >
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        canonical={seo.canonical}
        images={seo.opengraphImage || featuredImage?.node || null}
        modified={seo.opengraphModifiedTime}
        date={seo.opengraphPublishedTime}
        type="page"
      />
      <div>
        {!content && <div></div>}

        {content &&
          content.map((item, index) => {
            return findComponent(item, index, false)
          })}
      </div>

      <Section id="em-pauta">
        <div className="container py-24 px-4">
          <div tw="mb-12">
            <Title title="NOS ACOMPANHE" color="dark" tag="h2" center />
          </div>
          <div tw="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {featuredPosts.edges.map(({ node }) => (
              <MiniPost
                key={node.id}
                colorScheme="dark"
                post={node}
                layout="vertical-small"
              />
            ))}
          </div>
          <div tw="text-center">
            <Button
              link="/nos-acompanhe"
              color="orange"
              text="Ver todas as matérias"
            />
          </div>
        </div>
      </Section>

      <Partners />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query GET_PAGE_INDEX {
    wpPage(isFrontPage: { eq: true }) {
      acfHome {
        carousel {
          title
          caption
          link {
            target
            url
          }
          image {
            ...FeaturedImageFields
          }
        }
      }
      ...FlexiblePageFields
    }
    featuredPosts: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 3
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "estudos-e-publicacoes" } } }
        }
      }
    ) {
      edges {
        node {
          ...PostFields
          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
  }
`
